import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import {ContentService} from 'src/app/content.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-vipps-betaling',
  templateUrl: './vipps-betaling.component.html',
  styleUrls: ['./vipps-betaling.component.scss']
})
export class VippsBetalingComponent implements OnInit {
  belop: number;
  forestillingId: number;

  vippsOrderId: string;
  public ordre: Ordre;
  qrUrl: string;
  loadingQrCode: boolean;
  userStartedPayment: boolean;
  showNumberForm: boolean;
  errorMessage: string;

  private autoRefreshDelay = 5 * 1000;
  private refreshTimer;
  
  public form = new FormGroup({
    telefonnr: new FormControl()
  });

  constructor(
    private contentService: ContentService,
    private dialogRef: MdcDialogRef<VippsBetalingComponent>,
    @Inject(MDC_DIALOG_DATA) public data: any,
  ) {
    this.belop = this.data.belop;
    this.ordre = this.data.ordre;
    this.forestillingId = this.data.forestillingId;
    this.vippsOrderId = null;
    this.loadingQrCode = false;
    this.userStartedPayment = false;
  }

  ngOnInit(): void {
    this.errorMessage = null;
    this.showNumberForm = true;
  }

  ngOnDestroy(): void {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  }

  clickShowQrCode(): void {
    //console.log("ordre=", this.ordre);
    let url: string = `/salg/forestilling/${this.forestillingId}/ordre/${this.ordre.id}/vipps/qr`;
    let req = this.contentService.postContent(url, {amount: this.belop});
    this.loadingQrCode = true;
    this.showNumberForm = false;
    req.subscribe(res => {
      if (typeof res[0] !== 'undefined' && res[0].result === 'error') {
        this.errorMessage = 'Intern feil: Kunne ikke generere QR-kode.';
        this.userStartedPayment = false;
      } else {
        this.qrUrl = res.url;
        this.vippsOrderId = res.orderId;
        this.loadingQrCode = false;
        this.refreshTimer = setTimeout(() => this.getVippsStatus(), this.autoRefreshDelay);
      }
    });
  }

  clickShowNumberForm() {
    this.showNumberForm = true;
    this.qrUrl = null;
  }

  initiateVippsWithPhonenumber() {
    let phonenumber = this.form.value['telefonnr'];
    let url = `/salg/forestilling/${this.forestillingId}/ordre/${this.ordre.id}/vipps/initiate`;
    let req = this.contentService.postContent(url, {phonenumber: phonenumber, amount: this.belop});
    this.showNumberForm = false;
    this.userStartedPayment = true;
    req.subscribe(res => {
      if (typeof res[0] !== 'undefined' && res[0].result === 'error') {
        this.errorMessage = 'Kunne ikke starte Vipps-transaksjonen. Sjekk om telefonnummer er gyldig';
        this.userStartedPayment = false;
      } else {
        this.vippsOrderId = res.vipps_order_id;
        this.refreshTimer = setTimeout(() => this.getVippsStatus(), this.autoRefreshDelay);
      }
    });
  }

  getVippsStatus(): void {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.refreshTimer = setTimeout(() => this.getVippsStatus(), this.autoRefreshDelay);
    this.contentService.fetchContent(`/salg/forestilling/${this.forestillingId}/ordre/${this.ordre.id}/vipps/poll/${this.vippsOrderId}`)
      .subscribe((res: any) => {
        console.log("got res", res);
        // FIXME: qr-koden vil ikke funke akkurat nå
        if (res.reference == this.vippsOrderId && res.transactionLogHistory && res.transactionLogHistory.length >= 1 && res.transactionLogHistory[0].transactionId) {
          this.qrUrl = null;
          this.userStartedPayment = true;
        }
        let orderReserved = false;
        if (res.reference == this.vippsOrderId) {
            if (res.state == "AUTHORIZED") {
                orderReserved = true;
                res = {"updateOrder": true};
                this.dialogRef.close(res);
            }
            if (res.state == 'CANCELED') {
              this.errorMessage = 'Brukeren avbrøt betalingen';
            }
            if (res.state === 'CREATED') {
              // Ignore, brukeren har ikke interagert ennå
            }
        }
      });
  }
}
